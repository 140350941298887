<mat-sidenav-container>
  <mat-sidenav #sidenav position='end' class="menuMovil">
    <app-list-menu (sidenavClose)="sidenav.close()"></app-list-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-navbar (sidenavToggle)="sidenav.toggle()"></app-navbar>
    <main>
      <div class="contenedor">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>

    </main>
  </mat-sidenav-content>
</mat-sidenav-container>