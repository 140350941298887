<div class="section-us" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="70" fxFlex.xs="78" fxFlex.sm="80" fxFlex.md="70" fxFlex.lg="63" fxFlex.xl="70"  fxLayout="row wrap">
        <div fxFlex="100">
            <p class="title_us | color-wine-ligth">Nosotros</p>
        </div>
        <div fxFlex="100">
            <p class="text_us | color-black2 ">
                El Consejo Iberoamericano de la Empresa Familiar, A.C. Fue constituida el 05 de Octubre de 2020 como una
                Asociación Civil sin fines de lucro que agrupa a Familias Empresarias, Empresas Familiares y sus
                profesionales.
            </p><br><br>
            <p class="text_us | color-black2 "> El Consejo Iberoamericano de la Empresa Familiar integra y vincula a los diversos actores del ecosistema
                de la Empresa Familiar, para generar espacios de formación, vinculación y negocios, que faciliten a las
                familias empresarias lograr la armonía familiar, la rentabilidad de la empresa y el cuidado del
                patrimonio en pro de la continuidad y la prosperidad para las siguientes generaciones.
            </p>
        </div>

    </div>

</div>