import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AffiliateComponent } from './views/affiliate/affiliate.component';
import { ContactComponent } from './views/contact/contact.component';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { Services2Component } from './views/services2/services2.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'services', component: Services2Component },
  { path: 'affiliate', component: AffiliateComponent },
  { path: 'contact', component: ContactComponent },
  { path: '404', component: NotFoundComponent},
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
