<div class="section_services | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
       <img class="banner" fxFlex="100" src="../../../assets/img/services_section.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg > 
       <img class="banner" fxFlex="100" src="../../../assets/img/services_sectionlg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl> 
       <img class="banner" fxFlex="100" src="../../../assets/img/services_sectionlg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl> 
       <img class="banner" fxFlex="100" src="../../../assets/img/services_sectionsm.png" fxHide.xs fxHide.md fxHide.lg fxHide.xl> 
       <img class="banner" fxFlex="100" src="../../../assets/img/services_sectionxs.png" fxHide.sm fxHide.md fxHide.lg fxHide.xl> 
       <p class="text_banner" fxFlex="100"  fxLayoutAlign="center center">Servicios</p>
    </div>
    <div class="section_education" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="64" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row wrap" >
            <div fxFlex="60" fxFlex.xs="100"  fxFlex.sm="100" fxLayout="rwo wrap">
                <p class="title_red" fxFlex="100" >Educación</p>
                <p class="title_green" fxFlex="100" >Formación Especializada</p>
                <p class="title_gray" fxFlex="100" fxFlex.md="90" > Formación especializada para familias empresarias empresas familiares:</p>
                <p class="text" fxFlex="100" >-Programa de Alta Dirección de Empresas Familiares.<br>
                    -Programa de Formación de Dueños.<br>
                    -Programa de Formación de Generaciones Jóvenes de Empresa Familiar.<br>
                    -Programa de Formación de Emprendedores Familiares.<br>
                </p>
                <p class="title_gray"> Formación certificación de consultores y especialistas de empresa familiar:</p>
                <p class="text" fxFlex="100" >-Programa de formación de consultores de Empresa Familiar.<br>
                    -Programa de certificación de consultores de Empresa Familiar.<br>
                    -Programa de certificación de coaches de Empresa Familiar.<br>
                    -Programa de Actualización de especialistas de Empresa Familiar.<br>
                </p>
                <p class="title_gray" fxFlex="100" >Cátedra de la empresa familiar:</p>
                <p class="text" fxFlex="90" fxFlex.xs="100">-Diseño e Impartición de Cátedras de Empresa Familiar para Instituciones de Educación
                    Superior<br>
                </p>
                <p class="title_gray" fxFlex="100" >Biblioteca de la empresa familiar:</p>
                <p class="text" fxFlex="100" >-Publicaciones especializadas.<br>
                    -Investigaciones y estudios sobre la Empresa Familiar.<br>
                </p>

            </div>
            <div fxFlex="40" fxFlex.xs="100" fxLayout="row wrap">
                <img class="img_section mt-3" src="../../../assets/img/education_services.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg >
                <img class="img_section mt-3" src="../../../assets/img/education_serviceslg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl>
                <img class="img_section mt-3" src="../../../assets/img/education_serviceslg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl>
                <img class="img_section mt-3" src="../../../assets/img/education_servicessm.png"  fxHide.xs fxHide.md fxHide.lg fxHide.xl >
                <img class="img_section mt-3" src="../../../assets/img/education_servicesxs.png"  fxHide.sm fxHide.md fxHide.lg fxHide.xl >
            </div>
        </div>

    </div>
    <div class="sectión_profitability" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="64" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row wrap" >
            <div fxFlex="60" fxFlex.xs="100"  fxFlex.sm="100" fxLayout="column">
                <p class="title_red">Rentabilidad</p>
                <p class="title_orange">Desarrollo Integral</p>
                <p class="text">-Programa de Diagnóstico de Empresas Familiares.<br>
                    -Programa de Profesionalización de Empresas Familiares.<br>
                    -Programa de Institucionalización de Empresas Familiares. <br>
                    -Programa de Buenas Prácticas de Responsabilidad Social y Compliance para Empresas Familiares. <br>
                    -Programa de financiamiento para Empresas Familiares.
                </p>

            </div>
            <div fxFlex="40" fxFlex.xs="100" fxLayout="row wrap">
                <img class="img_section" src="../../../assets/img/profitability_services.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
                <img class="img_section" src="../../../assets/img/profitability_serviceslg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/profitability_serviceslg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl>
                <img class="img_section" src="../../../assets/img/profitability_servicessm.png"  fxHide.xs fxHide.md fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/profitability_servicesxs.png"  fxHide.sm fxHide.md fxHide.lg fxHide.xl>
            </div>
        </div>
    </div>
    <div class="section_bussines" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="64" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row wrap" >
            <div fxFlex="60" fxFlex.xs="100"  fxFlex.sm="100" fxLayout="row wrap">
                <p class="title_red" fxFlex="100" >Negocios</p>
                <p class="title_pink" fxFlex="100" >Vinculación y networking</p>
                <p class="text" fxFlex="90" fxFlex.xs="100" >
                    -Vinculación entre Empresas Familiares, Familias Empresarias, Consultores y
                    Especialistas.<br>
                    -Foros y eventos de Networking entre Empresas Familiares y Profesionales.<br><br>
                </p> 
            </div>
            <div fxFlex="40" fxFlex.xs="100" fxLayout="row wrap">
                <img class="img_section" src="../../../assets/img/business_services.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
                <img class="img_section" src="../../../assets/img/business_serviceslg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/business_serviceslg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl>
                <img class="img_section" src="../../../assets/img/business_servicessm.png"  fxHide.xs fxHide.md fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/business_servicesxs.png" fxHide.sm fxHide.md fxHide.lg fxHide.xl>
            </div>
        </div>
    </div>
    <div class="sectión_harmony" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="64" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row wrap" >
            <div fxFlex="60" fxFlex.xs="100"  fxFlex.sm="100" fxLayout="column">
                <p class="title_red">Armonía</p>
                <p class="title_wine">Comunidad de familias empresarias</p>
                <p class="text">-Foros y Eventos especializados para Familias Empresarias.<br>
                    -Experiencias vivenciales para Familias Empresarias.<br>
                    -Programas de acompañamiento emocional a las Familias Empresarias.
                </p>

            </div>
            <div fxFlex="40" fxFlex.xs="100" fxLayout="row wrap">
                <img class="img_section" src="../../../assets/img/harmony_services.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
                <img class="img_section" src="../../../assets/img/harmony_serviceslg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/harmony_serviceslg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl>
                <img class="img_section" src="../../../assets/img/harmony_servicessm.png" fxHide.xs  fxHide.md fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/harmony_servicesxs.png"  fxHide.sm fxHide.md fxHide.lg fxHide.xl>
            </div>
        </div>
    </div>
    <div class="section_internationalization" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="64" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row wrap" >
            <div fxFlex="60" fxFlex.xs="100"  fxFlex.sm="100" fxLayout="column">
                <p class="title_red">Internacionalización</p>
                <p class="title_purple">Red global de expertos</p>
                <p class="text">-Directorio de Consultores, Asesores, Mentores, Mediadores, Coaches y especialistas de
                    Empresa Familiar. <br>
                    -Directorio de Consejeros.
                </p>
            </div>
            <div fxFlex="40" fxFlex.xs="100" fxLayout="row wrap">
                <img class="img_section" src="../../../assets/img/internationalization_services.png" fxHide.xs fxHide.sm fxHide.md fxHide.lg>
                <img class="img_section" src="../../../assets/img/internationalization_serviceslg.png" fxHide.xs fxHide.sm fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/internationalization_serviceslg.png" fxHide.xs fxHide.sm fxHide.md fxHide.xl>
                <img class="img_section" src="../../../assets/img/internationalization_servicessm.png"  fxHide.xs fxHide.md fxHide.lg fxHide.xl>
                <img class="img_section" src="../../../assets/img/internationalization_servicesxs.png"  fxHide.sm fxHide.md fxHide.lg fxHide.xl>
            </div>
        </div>
    </div>
</div>