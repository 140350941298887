<app-carousel></app-carousel>
<app-us></app-us>
<app-services></app-services>
<app-philosophy></app-philosophy>
<app-banner></app-banner>
<app-alliances></app-alliances>
<app-benefits></app-benefits>
<app-initiatives></app-initiatives>
<!-- <app-contact-home></app-contact-home> -->

