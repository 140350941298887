<div class="section_initiatives | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class=" | " fxFlex="70" fxFlex.sm="80" fxFlex.xs="90"  fxLayout="row wrap" >
        <div fxFlex="25" fxFlex.xs="100" fxLayoutAlign="center center">
            <p class="title_initiatives | color-wine-ligth">Iniciativas</p>
        </div>
        <div class="card_content"  fxFlex="25" fxFlex.xs="33" fxLayoutAlign="center center">
            <img class="icon_cont" fxFlex.xs="65"   src="../../../../assets/img/initiatives1.png">
            <img class="icon_contHover" fxFlex.xs="65"  src="../../../../assets/img/initiatives1_hover.png">
        </div>
        <div class="card_content2" fxFlex="25" fxFlex.xs="33" fxLayoutAlign="center center">
            <img class="icon_cont" fxFlex.xs="75"   src="../../../../assets/img/initiatives2.png">
            <img class="icon_contHover" fxFlex.xs="75"  src="../../../../assets/img/initiatives2_hover.png">
        </div>
        <div class="card_content3" fxFlex="25" fxFlex.xs="33" fxLayoutAlign="center center">
            <img class="icon_cont" fxFlex.xs="75"   src="../../../../assets/img/initiatives3.png">
            <img class="icon_contHover" fxFlex.xs="75"  src="../../../../assets/img/initiatives3_hover.png">
        </div>
    </div>
</div>