<section>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="carrousel" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
      <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100">
        <ngu-tile *nguCarouselDef="let carousel">
          <div fxFlex="100" fxLayout="row wrap" class="banner" fxLayoutAlign="center stretch">
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_gd}}" fxHide.xs fxHide.sm fxHide.md
              fxHide.lg>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_ch}}" fxHide.xs fxHide.sm  fxHide.md 
              fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_ch}}" fxHide.xs fxHide.sm fxHide.lg
              fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_sm}}" fxHide.xs fxHide.md
              fxHide.lg fxHide.xl>
            <img fxFlex="100" fxLayout="row wrap" class="video" src="{{carousel.img_xs}}" fxHide.sm fxHide.md fxHide.lg
              fxHide.xl>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
              <img fxFlex="100" fxLayout="row wrap" class="banner-img" src="{{carousel.icon}}">
              <p fxFlex="100" class="banner-title" [innerHTML]="carousel.title"></p>
              <span fxFlex="100" class="banner-frases" [innerHTML]="carousel.frases"></span>
            </div>
          </div>
        </ngu-tile>
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
            (click)="myCarousel.moveTo(i)"></li>
        </ul>
      </ngu-carousel>
    </div>

  </div>