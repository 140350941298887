import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  public carousel = [
    {
      img:"./../../../assets/img/education.png",
      title: "Educación",
      frases: "Formación especializada",
    },
    {
      img:"../../../../assets/img/profitability.png",
      title: "Rentabilidad",
      frases: "Desarrollo integral",
    },
    {
      img:"../../../../assets/img/internationalization.png",
      title: "Internacionalización",
      frases: "Red global de expertos",
    },
    {
      img:"../../../../assets/img/harmony.png",
      title: "Armonía",
      frases: "Comunidad de familias empresarias",
    },
    {
      img:"../../../../assets/img/business.png",
      title: "Negocios",
      frases: "Vinculación y networking",
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };


  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  services(){
    this.router.navigate(["/services"]);

  }
}
