import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/components/messages/error/error.component';
import { SuccessComponent } from 'src/app/components/messages/success/success.component';
import { ServiceService } from 'src/app/services/service.services';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css']
})
export class AffiliateComponent implements OnInit {
  @ViewChild('fo') myForm;
  public form: FormGroup;
  public submitted: boolean = false;
  public showLoad: boolean = false;
  public listSector;
  public sectorSelect;
  public sectorOption;
  public idSector;



  constructor(private servicio: ServiceService,
    public dialog: MatDialog) {
    this.showLoad = true;
    this.servicio.sendListAffiliate().subscribe((res) => {
      this.listSector = res
      console.log(this.listSector);
      this.showLoad = false;
    }, (error) => {
      console.log(error);
      this.showLoad = false;
    });
  }

  ngOnInit(): void {
    this.submitted = false;
    this.doForm();
  }

  changeSector() {
    console.log('-------------');
    console.log(this.sectorOption);
    for (let i = 0; i < this.listSector.length; i++) {    
      const element = this.listSector[i].description ;
      if (element ==  this.sectorOption) {
        this.idSector = this.listSector[i].id
        console.log(this.idSector);
      }else {
        if(this.sectorOption == "Emprendedores e independientes interesados en la empresa familiar"){
          this.idSector = 1
          console.log(this.idSector);
        }else if(this.sectorOption == "Firmas de consultorías, consultores y especialistas"){
          this.idSector = 2
          console.log(this.idSector);
        }
      }
    }
  }

  doForm(): void {
    this.form = new FormGroup({
      sector: new FormControl('', [Validators.required]),
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
    });
  }

  submit() {
    console.log(this.form.value);
    if (this.form.invalid) {
      this.submitted = true;
      return;
    }
    const dataJson = {
        "nombre": this.form.value.full_name,
        "correo": this.form.value.email,
        "pais": this.form.value.country,
        "telefono": this.form.value.phone,
        "CategoriaSectorID": Number(this.form.value.sector)
    }
    this.showLoad = true;
    this.servicio.sendAffiliate(dataJson).subscribe((res) => {
      this.showLoad = false;
      this.submitted = false;

      let dialogSucces = this.dialog.open(SuccessComponent, {
        width: '650px',
        data: {
          title: 'Tus datos se enviaron correctamente.',
          message: 'Gracias por confiar en CIBAEF, uno de nuestros asesores se comunicará contigo.'
        }
      });
      dialogSucces.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('country').setValue('');
        this.form.get('sector').setValue('');
      });
    }, (error) => {
      this.showLoad = false;
      console.log(error);
      let dialogError = this.dialog.open(ErrorComponent, {
        width: '650px',
        data: {
          title: 'Error',
          message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
        },
        disableClose: true
      });
      dialogError.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('country').setValue('');
        this.form.get('sector').setValue('');
      });
    });
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
