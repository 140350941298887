<div class="section_contact | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <img class="banner" fxFlex="100" src="../../../assets/img/404_xl.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.lg>
        <img class="banner" fxFlex="100" src="../../../assets/img/404_lg.png" fxHide.xs fxHide.sm fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/404_lg.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/404_sm.png" fxHide.xs fxHide.md fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/404_xs.png" fxHide.sm fxHide.md fxHide.lg
            fxHide.xl>
    </div>
    <div class="title | color-white" fxFlex="100"  fxLayoutAlign="start start">
        <p >ERROR <span class="num">404</span></p>
    </div>
    <div class="text | color-white" fxFlex="100" fxLayoutAlign="start start">
        <p >PÁGINA NO ENCONTRADA</p>
    </div>
    <div fxFlex="100" fxLayoutAlign="start start">
        <button class="button_submit | background-white color-wine-ligth3 " type="submit"
            (click)="home()">Ir a Inicio</button>
    </div>
    
</div>