<div class="cont" fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">

  <div class="footer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="" fxFlex="70" fxFlex.xs="90" fxFlex.sm="85" fxFlex.md="90" fxFlex.lg="85" fxLayout="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="center center">
      <div class="footer__logo mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="50" fxLayout="row wrap"
         fxLayoutAlign="center center" fxLayoutAlign.md="center start" fxHide.sm>
        <img fxFlex="35" fxFlex.xs="30"  routerLink="/home" (click)="scrollTop()" class="logo" src="../../../assets/img/icon_tree.png" alt="CIBAEF">
        <div fxFlex="100" fxLayoutAlign="center center">
          <p fxFlex="66" fxFlex.md="77" fxFlex.lg="77"  class="text_tree">“Familias empresarias en armonía y Empresas Familiares exitosas… generación a
            generación”</p>
        </div>
      </div>
      <div fxFlex="75" fxFlex.xs="90" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="space-between ">
        <div class="footer__logo mt-3" fxFlex="25" fxFlex.xs="100" fxFlex.sm="60"fxFlex.md="55"  fxLayout="row wrap"
          fxLayoutAlign="center center" fxHide.gt-sm fxHide.xs>
          <img fxFlex="35" fxFlex.xs="50" routerLink="/home" (click)="scrollTop()" fxFlex.sm="24" class="logo" src="../../../assets/img/icon_tree.png" alt="CIBAEF">
          <div fxFlex="100" fxLayoutAlign="center center">
            <p fxFlex="66" fxFlex.lg="77" class="text_tree">“Familias empresarias en armonía y Empresas Familiares exitosas… generación
              a
              generación”</p>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="50" fxFlex.sm="40"fxFlex.md="18"   fxFlex.lg="15" fxLayoutAlign="start center"
          fxLayoutAlign.xs="center start" fxLayout="column">
          <div fxFlex="50" fxFlex.xs="50" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="center center">
            <p class="title | color-main" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center"
              fxFlex.xs="100" fxFlex.sm="100">Enlaces Rápidos</p>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/home" (click)="scrollTop()" fxFlex="100">
                  Inicio
                </a>
              </div>
              <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/services" (click)="scrollTop()" fxFlex="100">Servicios</a>
              </div>
              <!-- <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/affiliate" (click)="scrollTop()" fxFlex="100">Afíliate</a>
              </div> -->
              <!-- <div class="redes" fxFlex="100" fxLayout="row wrap" fxLayoutAlign.xs="center center"
                fxLayoutAlign.sm="center center">
                <a class="redes" routerLink="/contact" (click)="scrollTop()" fxFlex="100">Contacto</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="50" fxFlex.sm="30"fxFlex.md="25"  fxLayoutAlign="start center"
          fxLayout="column" fxHide.sm>
          <div fxFlex="100">
            <p class="title | color-main">Contáctanos</p>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a class="redes" href="https://api.whatsapp.com/send?phone=+525516526254" target="_blank" fxFlex="100">
                (+52) 55 1652 6254
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a class="redes" href="mailto:direccion@cibaef.org?Subject=Contacto%20CIBAEF" target="_blank" fxFlex="100">
                direccion@cibaef.org
              </a>
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="50" fxFlex.sm="30" fxFlex.md="25"  fxLayoutAlign="start center"
          fxLayout="column">
          <div fxFlex="100">
            <p class="title | color-main">Información</p>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Política de Privacidad
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center start" fxLayoutAlign.sm="center start">
              <a class="redes" routerLink="/home" target="_blank" fxFlex="100">
                Términos y condiciones
              </a>
            </div>
          </div>
        </div>
        <div class="footer__address mt-3" fxFlex="25" fxFlex.xs="50" fxFlex.sm="30"fxFlex.md="25"  fxLayoutAlign="start center"
          fxLayout="column" fxHide.gt-sm fxHide.xs>
          <div fxFlex="100">
            <p class="title | color-main">Contáctanos</p>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a href="https://api.whatsapp.com/send?phone=+525516526254" target="_blank" fxFlex="100">
                (+52) 55 1652 6254
              </a>
            </div>
            <div class="redes" fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
              <a href="mailto:direccion@cibaef.org?Subject=Contacto%20CIBAEF" target="_blank" fxFlex="100">
                direccion@cibaef.org
              </a>
            </div>
          </div>
        </div>
        <div class="footer__social mt-3" fxFlex="25" fxFlex.xs="40" fxFlex.sm="30" fxFlex.md="30" fxLayoutAlign="start center"
          fxLayout="row wrap" fxLayoutAlign.xs="start center" fxLayoutAlign.sm="start center" fxLayoutAlign.md="start start" fxLayoutAlign.lg="start start" fxLayoutAlign.xl="start start">
          <p class="title | color-main" fxFlex="60" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start center">Síguenos
          </p>
          <div class="logos" fxFlex="100" fxLayout="row" fxLayoutAlign="start center" >
            <div class="container" fxLayoutAlign="center center" id="facebook">
              <div class="card front">
                <a href="https://www.facebook.com/Consejo-Iberoamericano-de-la-Empresa-Familiar-100715852094770/" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/fb_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.facebook.com/Consejo-Iberoamericano-de-la-Empresa-Familiar-100715852094770/" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/fb_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
              <div class="card front">
                <a href="https://twitter.com/ConsejoFamiliar?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/tw_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://twitter.com/ConsejoFamiliar?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/tw_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
              <div class="card front">
                <a href="https://www.linkedin.com/company/consejo-iberoamericano-de-la-empresa-familiar/?originalSubdomain=mx" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/in_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.linkedin.com/company/consejo-iberoamericano-de-la-empresa-familiar/?originalSubdomain=mx" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/in_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
                <div class="card front">
                  <a href="https://www.instagram.com/cibaef/" target="_blank">
                      <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/insta_icon.svg" alt="CIBAEF"/>
                  </a>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                  <a href="https://www.instagram.com/cibaef/" target="_blank">
                      <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/insta_iconB.svg" alt="CIBAEF"/>
                  </a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="derechos | color-white" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="80" fxLayout="row wrap" fxLayoutAlign="center center">
      <span class="redes2" >Copyright &#169; 2022 | Desarrollado con <img class="heart" src="/assets/icon/love.svg" alt="CIBAEF"> por
      </span>
      <a href="https://www.polimentes.com/" target="_blank"><img class="polimentes ml-5 |"
          src="/assets/img/Polimentes_logo.png" alt="Polimentes">
      </a>
    </div>
    <div fxFlex="100" fxFlex.sm="10" fxFlex.md="10" fxFlex.lg="10" fxFlex.xl="10" fxLayoutAlign="center center">
      <p class=" | color-main" fxFlex="60" fxLayoutAlign="center center" fxLayoutAlign.xs="center center">v1.2.10</p>

    </div>
  </div>
</div>