<mat-nav-list class="nav__container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end stretch">
    <mat-list class="nav__caption" fxLayout="column" fxFlex="100">
        <mat-list-item class="nav__close" fxLayoutAlign="end center">
            <img src="/assets/icon/close.svg" alt="X" (click)="onSidenavClose()">
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['home']" (click)="onSidenavClose()">Inicio</span>
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['services']" (click)="onSidenavClose()">Servicios</span>
        </mat-list-item>
        <!-- <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['affiliate']" (click)="onSidenavClose()">Afíliate</span>
        </mat-list-item>
        <mat-list-item fxLayoutAlign="start center">
            <span class="nav__link" [routerLink]="['contact']" (click)="onSidenavClose()">Contacto</span>
        </mat-list-item> -->
        <mat-list-item fxLayoutAlign="start center" fxFlex="100" >
            <div class="logos" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
                <div fxFlex="23" class="nav__socialContainer" fxLayoutAlign="center center" id="facebook">
                    <a href="https://www.facebook.com/Consejo-Iberoamericano-de-la-Empresa-Familiar-100715852094770/" target="_blank">
                        <img fxFlex="90" src="/assets/icon/fb_icon.svg" alt="CIBAEF"/>
                    </a>
                </div>
                <div fxFlex="23" class="nav__socialContainer" fxLayoutAlign="center center">
                    <a href="https://twitter.com/ConsejoFamiliar?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                        <img fxFlex="90" src="/assets/icon/tw_icon.svg" alt="CIBAEF"/>
                    </a>
                </div>
                <div fxFlex="23" class="nav__socialContainer" fxLayoutAlign="center center">
                    <a href="https://www.linkedin.com/company/consejo-iberoamericano-de-la-empresa-familiar/?originalSubdomain=mx" target="_blank">
                        <img fxFlex="90" src="/assets/icon/in_icon.svg" alt="CIBAEF"/>
                    </a>
                </div>
                <div fxFlex="23" class="nav__socialContainer" fxLayoutAlign="center center">
                    <a href="https://www.instagram.com/cibaef/" target="_blank">
                        <img fxFlex="90" src="/assets/icon/insta_icon.svg" alt="CIBAEF"/>
                    </a>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</mat-nav-list>