
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalUrl } from '../models/url-model';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  public sendAffiliate(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(GlobalUrl.URL_Produccion_Cibaef + 'affiliations/web/v1/ApiAff/', data);
  }
  public sendListAffiliate(): Observable<any> {
    console.log()
    return this.http.get(GlobalUrl.URL_Produccion_Cibaef + 'affiliations/web/v1/ApiLitCatSec/');
  }

  public sendContact(data: any): Observable<any> {
    console.log("Info enviada",data)
    return this.http.post(GlobalUrl.URL_Produccion_Cibaef + 'contact/web/v1/ApiCreCon/', data);
  }
}
