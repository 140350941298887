import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


/**Translate */
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
/**Flex */
import { FlexLayoutModule } from '@angular/flex-layout';
/**Angular Material */
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
/**Carrusel */
import { NguCarouselModule } from '@ngu/carousel';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


/**/
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { ContactComponent } from './views/contact/contact.component';
import { AffiliateComponent } from './views/affiliate/affiliate.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { BannerComponent } from './views/home/banner/banner.component';
import { UsComponent } from './views/home/us/us.component';
import { PhilosophyComponent } from './views/home/philosophy/philosophy.component';
import { CarouselComponent } from './views/home/carousel/carousel.component';
import { AlliancesComponent } from './views/home/alliances/alliances.component';
import { BenefitsComponent } from './views/home/benefits/benefits.component';
import { Services2Component } from './views/services2/services2.component';
import { ServicesComponent } from './views/home/services/services.component';
import { InitiativesComponent } from './views/home/initiatives/initiatives.component';
import { ListmenuComponent } from './components/listmenu/listmenu.component';
import { ErrorComponent } from './components/messages/error/error.component';
import { SuccessComponent } from './components/messages/success/success.component';
import { ContactHomeComponent } from './views/home/contact-home/contact-home.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    AffiliateComponent,
    Services2Component,
    NotFoundComponent,
    BannerComponent,
    UsComponent,
    PhilosophyComponent,
    CarouselComponent,
    AlliancesComponent,
    BenefitsComponent,
    ServicesComponent,
    InitiativesComponent,
    ListmenuComponent,
    ErrorComponent,
    SuccessComponent,
    ContactHomeComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NguCarouselModule,
    MatProgressSpinnerModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
