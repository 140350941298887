<div class="section-services | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="65" fxFlex.xs="70" fxFlex.sm="77" fxFlex.md="75" fxFlex.lg="75" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
            <p class="title_us | color-wine-ligth">Servicios</p>
        </div>
        <div fxFlex="100" fxLayout="row wrap">
            <div class="carrousel" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
                <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100">
                  <ngu-tile *nguCarouselDef="let carousel">
                    <div fxFlex="100" fxLayout="row wrap" class="banner" fxLayoutAlign="center stretch">
                      <img fxFlex="95" fxLayout="row wrap" class="video" src="{{carousel.img}}" >
                      <div class="text_contet" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
                        <span  class="banner-frases2" [innerHTML]="carousel.title"></span>
                        <span  class="banner-frases" [innerHTML]="carousel.frases"></span>
                      </div>
                    </div>
                  </ngu-tile>
                  <ul class="myPoint" NguCarouselPoint>
                    <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
                      (click)="myCarousel.moveTo(i)"></li>
                  </ul>
                </ngu-carousel>
              </div>
        </div>
        <div fxFlex="100" fxLayoutAlign="center center">
          <button class="button_submit | background-wine-ligth3 color-white " type="button"
              (click)="services()">VER MÁS</button>
      </div>
    </div>
</div>