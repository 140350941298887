<div class="section_contact | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <img class="banner" fxFlex="100" src="../../../assets/img/affiliate_xl.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.lg>
        <img class="banner" fxFlex="100" src="../../../assets/img/affiliate_lg.png" fxHide.xs fxHide.sm fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/affiliate_lg.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/affiliate_sm.png" fxHide.xs fxHide.md fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/affiliate_xs.png" fxHide.sm fxHide.md fxHide.lg
            fxHide.xl>
        <div fxFlex="100" fxLayoutAlign="center center">
            <p fxFlex="60" fxFlex.xs="90" class="text_banner | color-white">Únete a una gran comunidad de Familias
                Empresarias, Empresas Familiares y sus Profesionales.</p>
        </div>
    </div>
    <div class="section_form" fxFlex="45" fxFlex.xs="85" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="65"
        fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="form_content" fxFlex="100" fxLayout="row wrap">
            <div class="form-contact" fxFlex="100" fxLayout="column">
                <div fxFlex="100">
                    <p class="title_contact | color-wine-ligth">Afíliate</p>
                </div>
                <form id="formulario" [formGroup]="form" autocomplete="off" #fo="ngForm" fxFlex="100"
                    fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.sm="center center">
                    <div class="form" fxFlex="100">
                        <span class="" fxLayout="column">
                            <!-- <select class="select-css"
                                [ngClass]="{error: submitted && form.get('sector').hasError('required')}" name="sector"
                                id="sector" formControlName="sector" [(ngModel)]="sectorOption" (click)="changeSector()"
                                required>
                                <option value="">¿A qué sector perteneces?*</option>
                                <option *ngFor="let item of listSector">{{item.description}}</option>
                            </select> -->
                            <select class="select-css"
                            [ngClass]="{error: submitted && form.get('sector').hasError('required')}" name="sector"
                            id="sector" formControlName="sector" required>
                                <option value="" selected>¿A qué sector perteneces?*</option>
                                <option *ngFor="let item of listSector" [value]="item.id">
                                    {{item.description}}
                                </option>
                            </select>
                        </span>
                        <div *ngIf="submitted && form.get('sector').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form mr-3" fxFlex="48" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="" [ngClass]="{error: submitted && form.get('full_name').hasError('required')}"
                                type="text" name="name" id="name" placeholder="Nombre Completo*"
                                formControlName="full_name">
                        </span>
                        <div *ngIf="submitted && form.get('full_name').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="47" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="" [ngClass]="{error: submitted && form.get('country').hasError('required')}"
                                type="text" name="country" id="country" placeholder="País*" formControlName="country">
                        </span>
                        <div *ngIf="submitted && form.get('country').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form mr-3" fxFlex="48" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""
                                [ngClass]="{error: submitted && form.get('email').hasError('required'),errorEmail: submitted && form.get('email').hasError('email')}"
                                type="email" name="email" id="email" placeholder="Correo electrónico*"
                                formControlName="email">
                        </span>
                        <div *ngIf="submitted && form.get('email').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                        <div *ngIf="submitted && form.get('email').hasError('email')">
                            <span class="alertRed">Ingrese un correo valido</span>
                        </div>
                    </div>

                    <div class="form" fxFlex="47" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="" [ngClass]="{error: submitted && form.get('phone').hasError('required')}" type="tel"
                                name="phone" id="phone" placeholder="Teléfono*" formControlName="phone"
                                (keypress)="keyPressNumbers($event)" maxlength="10">
                        </span>
                        <div *ngIf="submitted && form.get('phone').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                        <button class="button_submit | background-wine-ligth3 color-white " type="submit"
                            (click)="submit()">Afiliarme</button>
                    </div>

                </form>

            </div>
        </div>

    </div>
    <div fxFlex="100" fxFlex.xs="75" fxLayoutAlign="center center">
        <p class="text_affiliate">Un espacio para estar cerca de ti.</p>
    </div>

</div>
<app-loading *ngIf="showLoad"></app-loading>