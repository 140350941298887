<div class="section_contact | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="content_banner" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <img class="banner" fxFlex="100" src="../../../assets/img/contact_xl.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.lg>
        <img class="banner" fxFlex="100" src="../../../assets/img/contact_lg.png" fxHide.xs fxHide.sm fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/contact_lg.png" fxHide.xs fxHide.sm fxHide.md
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/contact_sm.png" fxHide.xs fxHide.md fxHide.lg
            fxHide.xl>
        <img class="banner" fxFlex="100" src="../../../assets/img/contact_xs.png" fxHide.sm fxHide.md fxHide.lg
            fxHide.xl>
    </div>
    <div class="section_form" fxFlex="70" fxFlex.xs="85" fxFlex.md="85" fxLayout="row wrap"
        fxLayoutAlign="center start">
        <div fxFlex="30" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="icon_cibaef" fxFlex="60" fxFlex.xs="75" fxFlex.sm="45"
                src="../../../assets/img/contact_iconCIBAEF.png">
            <p class="phrase" fxFlex="100">“Familias empresarias en armonía<br> y Empresas Familiares exitosas”</p>
        </div>
        <div class="form_content" fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row wrap">
            <div class="form-contact " fxLayout="column">
                <div fxFlex="100">
                    <p class="title_contact | color-wine-ligth">Contacto</p>
                </div>
                <form id="formulario" [formGroup]="form" autocomplete="off" #fo="ngForm" fxFlex="100"
                    fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.sm="center center">
                    <div class="form mr-3" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class="" [ngClass]="{error: submitted && form.get('full_name').hasError('required')}" type="text" name="name" id="name" placeholder="Nombre Completo*"
                                formControlName="full_name">
                        </span>
                        <div *ngIf="submitted && form.get('full_name').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""  [ngClass]="{error: submitted && form.get('country').hasError('required')}" type="text" name="country" id="country" placeholder="País*"
                                formControlName="country">
                        </span>
                        <div *ngIf="submitted && form.get('country').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form mr-3" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""   [ngClass]="{error: submitted && form.get('email').hasError('required'), errorEmail: submitted && form.get('email').hasError('email')}" type="email" name="email" id="email" placeholder="Correo*" formControlName="email">
                        </span>
                        <div *ngIf="submitted && form.get('email').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                        <div *ngIf="submitted && form.get('email').hasError('email')">
                            <span class="alertRed">Ingrese un correo valido</span>
                        </div>
                    </div>

                    <div class="form" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""  [ngClass]="{error: submitted && form.get('phone').hasError('required')}" type="tel" name="phone" id="phone" placeholder="Teléfono*" formControlName="phone"
                                (keypress)="keyPressNumbers($event)" maxlength="10">
                        </span>
                        <div *ngIf="submitted && form.get('phone').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div class="form mr-3" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""  type="text" name="empresa" id="empresa" placeholder="Empresa"
                                formControlName="company">
                        </span>
                    </div>
                    <div class="form" fxFlex="45" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <input class=""  type="tel" name="numColaborators" id="numColaborators"
                                placeholder="Número de Colaboradores" formControlName="numColaborators"
                                (keypress)="keyPressNumbers($event)">
                        </span>
                    </div>
                    <div class="form" fxFlex="95" fxFlex.xs="100">
                        <span class="" fxLayout="column">
                            <textarea class=""  [ngClass]="{errorMsg: submitted && form.get('message').hasError('required')}" name="message" id="message" placeholder="¿Cómo podemos ayudarte?*" maxlength="251"
                                formControlName="message"></textarea>
                        </span>
                        <div *ngIf="submitted && form.get('message').hasError('required')">
                            <span class="alertRed">Este campo es obligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="start center">
                        <button class="button_submit | background-wine-ligth3 color-white " type="submit"
                            (click)="submit()">Enviar</button>
                    </div>

                </form>

            </div>
        </div>

    </div>

</div>
<app-loading *ngIf="showLoad"></app-loading>