<div class="section-philosophy" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="75" fxFlex.xs="78" fxFlex.sm="85" fxFlex.md="80" fxLayout="row wrap" fxLayoutAlign="space-evenly center" >
        <div fxFlex="40" fxLayout="row wrap" fxHide.xs fxHide.sm fxHide.lg fxHide.md>
            <img   src="../../../../assets/img/philosophy_desktgde.png">
        </div>
        <div fxFlex="48"  fxLayout="row wrap" fxHide.xs fxHide.sm fxHide.xl fxHide.gt>
            <img  fxFlex="100"  src="../../../../assets/img/philosophy.png">
        </div>
        <div fxFlex="45"   fxLayout="row wrap" fxHide.xs fxHide.lg fxHide.md fxHide.xl fxHide.gt>       
            <img   src="../../../../assets/img/philosophy_tablet.png">
        </div>
        <div fxFlex="50" fxFlex.xs="100" fxFlex.sm="45" fxFlex.md="45" fxFlex.lg="45" fxLayout="row wrap">
            <div fxFlex="100">
                <p class="title_us | color-wine-ligth">Filosofía</p>
            </div>
            <div fxFlex="85" fxFlex.xs="98" fxFlex.sm="100" fxFlex.md="100" fxFlex.md-lg="90">
                <p class="subtitle_us | color-wine-ligth3">MISIÓN:</p>
                <p class="text_us | color-black2 ">
                    Somos una organización que representa y apoya a las Empresas Familiares y las Familias Empresarias,
                    procurando su armonía, institucionalización y cuidado del patrimonio para lograr su éxito y
                    continuidad.
                </p>
                <p class="subtitle_us | color-wine-ligth3">VISIÓN:</p>
                <p class="text_us | color-black2 "> Ser el referente de representación, actualización y fomento de
                    buenas prácticas de gobierno corporativo para las Empresas Familiares y las Familias Empresarias.
                </p>
            </div>
        </div>
        <div fxFlex="100"  fxLayout="row wrap" fxHide.sm fxHide.lg fxHide.md fxHide.xl fxHide.gt>
            <img class="img_mobile" fxFlex="100"    src="../../../../assets/img/philosophy_tablet.png">
        </div>


    </div>

</div>