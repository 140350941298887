import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/components/messages/error/error.component';
import { SuccessComponent } from 'src/app/components/messages/success/success.component';
import { ServiceService } from 'src/app/services/service.services';

@Component({
  selector: 'app-contact-home',
  templateUrl: './contact-home.component.html',
  styleUrls: ['./contact-home.component.css']
})
export class ContactHomeComponent implements OnInit {
  @ViewChild('fo') myForm;
  public form: FormGroup;
  public submitted: boolean = false;
  public showLoad: boolean = false;

  constructor(private servicio: ServiceService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.submitted = false;
    this.doForm();

  }

  doForm(): void {
    this.form = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('',),
      numColaborators: new FormControl('',),
      message: new FormControl('', [Validators.required]),
    });
  }

  submit() {
    this.submitted = true;
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }
    const dataJson = {
        "nombre":  this.form.value.full_name,
        "correo":  this.form.value.email,
        "pais":  this.form.value.country,
        "telefono":  this.form.value.phone,
        "empresa":  this.form.value.company,
        "NumeroColaboradores":  this.form.value.numColaborators,
        "descripcion":  this.form.value.message,
    }
    dataJson.NumeroColaboradores = Number(dataJson.NumeroColaboradores);

    this.showLoad = true;
    this.servicio.sendContact(dataJson).subscribe((res) => {
      this.showLoad = false;

      let dialogSucces = this.dialog.open(SuccessComponent, {
        width: '605px',
        data: {
          title: 'Tu mensaje se envió correctamente.',
          message: 'Gracias por ponerte en contacto con nosotros, uno de nuestros asesores se comunicará contigo.'
        }
      });
      dialogSucces.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('company').setValue('');
        this.form.get('country').setValue('');
        this.form.get('city').setValue('');
        this.form.get('message').setValue('');
      });
    }, (error) => {
      this.showLoad = false;

      let dialogError = this.dialog.open(ErrorComponent, {
        width: '605px',
        data: {
          title: 'Error',
          message: '¡Tus datos no pudieron enviarse correctamente, intente mas tarde!'
        },
        disableClose: true

      });
      dialogError.afterClosed().subscribe(res => {
        this.dialog.closeAll();
        this.myForm.resetForm();
        this.form.reset();
        this.form.get('full_name').setValue('');
        this.form.get('email').setValue('');
        this.form.get('phone').setValue('');
        this.form.get('message').setValue('');
        this.form.get('vacant').setValue('');
        this.form.get('cv').setValue('');
      });
    });
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
