import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {


  constructor(private router: Router) { }

  affiliate(){
    this.router.navigate(["/affiliate"]);
  
  }
}
