<div class="section_benefits | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxLayoutAlign="center center">
        <p class="title_benefits | color-wine-ligth">Beneficios</p>
    </div>
    <div class="section_card" fxFlex="50" fxFlex.sm="71" fxFlex.md="75" fxFlex.lg="76"  fxFlex.xl="65" fxLayout="row wrap" fxLayoutAlign="space-around start" fxLayoutAlign.sm="space-around end">
        <div class="border_content card_content 1" fxFlex="20" fxFlex.xl="22" fxFlex.lg="24"  fxFlex.md="25" fxFlex.sm="50"
            fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="icon_cont" src="../../../../assets/icon/tracing.svg">
            <img class="icon_contHover" fxFlex="80" fxFlex.xs="77" fxFlex.sm="40"  fxFlex.md="65" fxFlex.lg="50"  fxFlex.xl="50" src="../../../../assets/icon/tracing_hover.svg">
            <div class="" fxFlex="100" fxLayoutAlign="center center">
                <p class="text_card" fxFlex="72" fxLayoutAlign="center center">SEGUIMIENTO Y ACOMPAÑAMIENTO PERMANENTE</p>
            </div>
        </div>
        <div class="border_content2 card_content2 |" fxFlex="20" fxFlex.xl="22" fxFlex.lg="24"  fxFlex.md="25" fxFlex.sm="50"
            fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="icon_cont" src="../../../../assets/icon/equipment.svg">
            <img class="icon_contHover" fxFlex="80" fxFlex.xs="75" fxFlex.sm="40" fxFlex.md="65" fxFlex.lg="50"   fxFlex.xl="50" src="../../../../assets/icon/equipment_hover.svg">
            <div class="" fxFlex="100" fxLayoutAlign="center center">
                <p class="text_card" fxFlex="72"  fxFlex.sm="72" fxLayoutAlign="center center">EQUIPO MULTIDISCIPLINARIO DE ALTO NIVEL</p>
            </div>
        </div>
        <div class="border_content3 card_content3" fxFlex="20" fxFlex.xl="22" fxFlex.lg="24"  fxFlex.md="25" fxFlex.sm="50"
            fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="icon_cont" src="../../../../assets/icon/prestige.svg">
            <img class="icon_contHover"  src="../../../../assets/icon/prestige_hover.svg">
            <div class="" fxFlex="100" fxLayoutAlign="center center">
                <p class="text_card" fxFlex="72" fxLayoutAlign="center center">PRESTIGIO DE LOS AFILIADOS</p>
            </div>
        </div>
        <div class="border_content4 card_content4 |" fxFlex="20" fxFlex.xl="22" fxFlex.lg="24"  fxFlex.md="25" fxFlex.sm="50"
            fxFlex.xs="80" fxLayout="row wrap" fxLayoutAlign="center center">
            <img class="icon_cont" src="../../../../assets/icon/national_networks.svg">
            <img class="icon_contHover"  src="../../../../assets/icon/national_networks_hover.svg">
            <div class="" fxFlex="100" fxLayoutAlign="center center">
                <p class="text_card" fxFlex="100"  fxFlex.md="92" fxFlex.lg="100" fxLayoutAlign="center center">REDES NACIONALES E INTERNACIONALES</p>
            </div>
        </div>
    </div>

</div>