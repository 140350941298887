<div [ngClass]="navbarfixed?'navbar2 background-wine shadow':'navbar background-active shadow'" fxLayout="row" fxLayoutAlign="space-between stretch" fxHide.xs fxHide.sm>
    <div class="navbar__content" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="20"></div>
        <div fxFlex="55" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <span class="navbar__link color-white" [routerLink]="['home']" (click)="scrollTop()">Inicio</span>
            <!-- <span class="navbar__link color-white" [routerLink]="['services']" (click)="scrollTop()">Servicios</span> -->
            <img src="/assets/icon/leter_cibaef.svg" [routerLink]="['home']" (click)="scrollTop()" alt="CIBAEF">
            <span class="navbar__link color-white" [routerLink]="['services']" (click)="scrollTop()">Servicios</span>
            <!-- <span class="navbar__link color-white" [routerLink]="['affiliate']" (click)="scrollTop()">Afíliate</span>
            <span class="navbar__link color-white" [routerLink]="['contact']" (click)="scrollTop()">Contacto</span> -->
        </div>
        <div class="logos" fxFlex="25" fxLayout="row" fxLayoutAlign="start center" fxHide.sm>
            <div class="container" fxLayoutAlign="center center" id="facebook">
              <div class="card front">
                <a href="https://www.facebook.com/Consejo-Iberoamericano-de-la-Empresa-Familiar-100715852094770/" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/fb_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.facebook.com/Consejo-Iberoamericano-de-la-Empresa-Familiar-100715852094770/" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/fb_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
              <div class="card front">
                <a href="https://twitter.com/ConsejoFamiliar?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/tw_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://twitter.com/ConsejoFamiliar?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="../../../assets/icon/tw_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
              <div class="card front">
                <a href="https://www.linkedin.com/company/consejo-iberoamericano-de-la-empresa-familiar/?originalSubdomain=mx" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/in_icon.svg" alt="CIBAEF"/>
                </a>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                <a href="https://www.linkedin.com/company/consejo-iberoamericano-de-la-empresa-familiar/?originalSubdomain=mx" target="_blank">
                    <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/in_iconB.svg" alt="CIBAEF"/>
                </a>
              </div>
            </div>
            <div class="container" fxLayoutAlign="center center">
                <div class="card front">
                  <a href="https://www.instagram.com/cibaef/" target="_blank">
                      <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/insta_icon.svg" alt="CIBAEF"/>
                  </a>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="center" class="card back">
                  <a href="https://www.instagram.com/cibaef/" target="_blank">
                      <img class="" fxFlex="100" fxFlex.md="65" fxFlex.lg="90" src="/assets/icon/insta_iconB.svg" alt="CIBAEF"/>
                  </a>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-toolbar [ngClass]="navbarfixed?'navbar2 background-wine shadow':'navbar background-wine shadow'"  fxFlex="100" fxLayout="row" fxLayoutAlign="center stretch" fxHide.gt-sm>
  <div class="nav_mov" fxFlex="100" fxFlex.sm="100" fxLayoutAlign="space-between center">
      <div fxFlex="100" fxFlex.sm="95" fxLayoutAlign="center center">
        <img [routerLink]="['home']" (click)=" scrollTop()"  src="/assets/icon/leter_cibaef.svg" alt="CIBAEF" fxLayoutAlign="center center">
      </div>
      <div fxFlex="9" fxFlex.sm="6" fxLayoutAlign="end center">
        <button class="button_menu" fxFlex="100" [routerLink]="['home']" (click)="onToggleSidenav()" fxLayoutAlign="center center">
       <img fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="70" fxFlex.lg="55" src="../../../assets/img/Grupo 7086.png" alt="Cibaef"/>

        </button>
      </div>
  </div>
</mat-toolbar>
<div id="inicio"></div>  