import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  public links = [{link: 'Inicio', fragment: 'inicio'}, {link: 'El Proceso', fragment: 'proceso'}, {link: 'Historia', fragment: 'historia'}, {link: 'Comprar', fragment: 'comprar'}, {link: 'Seamos Amigos', fragment: 'amigos'}, {link: 'Contacto', fragment: 'contacto'}]
  public active: number = 0;
  public navbarfixed: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY < 50) {
      this.navbarfixed = false;
    } else {
      this.navbarfixed = true;
    }
  }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

}
