import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent  {

  public carousel = [
    {
      img_gd:"../../../../assets/img/Cibaef_carousel1.png",
      img_ch:"../../../../assets/img/Cibaef_carousel1_lg.png",
      img_sm:"../../../../assets/img/Cibaef_carousel1_sm.png",
      img_xs:"../../../../assets/img/Cibaef_carousel1_xs.png",
      icon: "../../../../assets/icon/tree_cibaef.svg",
      title: "Consejo Iberoamericano de la Empresa Familiar",
      frases: "“Familias empresarias en armonía y Empresas Familiares exitosas”",
    },
    {
      img_gd:"../../../../assets/img/Cibaef_carousel2.png",
      img_ch:"../../../../assets/img/Cibaef_carousel2_lg.png",
      img_sm:"../../../../assets/img/Cibaef_carousel2_sm.png",
      img_xs:"../../../../assets/img/Cibaef_carousel2_xs.png",
      icon: "../../../../assets/icon/tree_cibaef.svg",
      title: "Consejo Iberoamericano de la Empresa Familiar",
      frases: "“Familias empresarias en armonía y Empresas Familiares exitosas”",
    },
    {
      img_gd:"../../../../assets/img/Cibaef_carousel3.png",
      img_ch:"../../../../assets/img/Cibaef_carousel3_lg.png",
      img_sm:"../../../../assets/img/Cibaef_carousel3_sm.png",
      img_xs:"../../../../assets/img/Cibaef_carousel3_xs.png",
      icon: "../../../../assets/icon/tree_cibaef.svg",
      title: "Consejo Iberoamericano de la Empresa Familiar",
      frases: "“Familias empresarias en armonía y Empresas Familiares exitosas”",
    },
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };

  constructor() {}

  // ngOnInit(): void {
  //   AOS.init({
  //     duration: 2000,
  //   });
  // }

}
