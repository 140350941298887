<div class="section_alliances | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xs fxHide.sm fxHide.md fxHide.lg  >
    <div fxFlex="100" fxLayoutAlign="center center">
        <p class="title_alliances | color-wine-ligth">Alianzas</p>
    </div>
    <div class="section_card" fxFlex="90" fxLayout="row wrap" fxLayoutAlign="space-around start" >
        <div class="card_content" fxFlex="16" fxLayout="row wrap"  fxLayoutAlign="center center"   *ngFor="let alliance of alliances">
            <div fxLayoutAlign="center center">
                <img [src]="alliance.img">
            </div>
            <div class="text_card text_cardH" fxFlex="100" fxLayoutAlign="center center">
                <p class="text_card"  fxFlex="72"  fxLayoutAlign="center center"  [innerHTML]="alliance.text "></p>
            </div>
        </div>
    </div>
</div>
<div class="section_alliances | " fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" fxHide.xl>
    <div fxFlex="100" fxLayoutAlign="center center">
        <p class="title_alliances | color-wine-ligth">Alianzas</p>
    </div>
    <div class="carrousel" fxFlex="100" fxFlex.md="85" fxFlex.lg="75" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch">
        <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="carousel" fxFlex="100">
          <ngu-tile *nguCarouselDef="let carousel">
            <div fxFlex="100" fxLayout="row wrap" class="banner" fxLayoutAlign="center stretch">
                <div class="section_card" fxFlex="95" fxFlex.xs="68" fxFlex.sm="77" fxFlex.lg="90"  fxLayout="row wrap" fxLayoutAlign="space-around start" >
                    <div class="card_content" fxFlex="100" fxLayout="row wrap"  fxLayoutAlign="center center"   >
                        <div fxLayoutAlign="center center" fxFlex="100">
                            <img fxFlex="100" src="{{carousel.img}}">
                        </div>
                        <div class="text_card text_cardH" fxFlex="100" fxLayoutAlign="center center">
                            <p class="text_card"  fxFlex="72"  fxLayoutAlign="center center"  [innerHTML]="carousel.text"></p>
                        </div>
                    </div>
                </div>
            </div>
          </ngu-tile>
          <ul class="myPoint" NguCarouselPoint>
            <li *ngFor="let i of myCarousel.pointNumbers" [class.active]="i==myCarousel.activePoint"
              (click)="myCarousel.moveTo(i)"></li>
          </ul>
        </ngu-carousel>
      </div>
</div>