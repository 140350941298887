import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-alliances',
  templateUrl: './alliances.component.html',
  styleUrls: ['./alliances.component.css']
})
export class AlliancesComponent implements OnInit {
  public carousel = [
    {
      img:'../../../../assets/img/chambers and associations.png',
      text: 'Cámaras, asociaciones y organismos empresariales.',
    },
    {
      img:'../../../../assets/img/universities.png',
      text: 'Universidades públicas y privadas.',
    },
    {
      img:'../../../../assets/img/schools.png',
      text: 'Colegios de profesionales.',
    },
    {
      img:'../../../../assets/img/government.png',
      text: 'Gobierno federal y estatal.',
    },
    {
      img:'../../../../assets/img/organizations.png',
      text: 'Organizaciones de la sociedad civil.',
    },
    {
      img:'../../../../assets/img/associations.png',
      text: 'Asociaciones internacionales y organismos multilaterales.',
    },  
  ];
  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
    speed: 250,
    point: { visible: true },
    touch: true,
    loop: true,
    load: 2,
    interval: {
      timing: 10000,
      initialDelay: 10000,
    },
    animation: 'lazy',
  };

  public alliances = [
    {
      img:'../../../../assets/img/chambers and associations.png',
      text: 'Cámaras, asociaciones y organismos empresariales.',
    },
    {
      img:'../../../../assets/img/universities.png',
      text: 'Universidades públicas y privadas.',
    },
    {
      img:'../../../../assets/img/schools.png',
      text: 'Colegios de profesionales.',
    },
    {
      img:'../../../../assets/img/government.png',
      text: 'Gobierno federal y estatal.',
    },
    {
      img:'../../../../assets/img/organizations.png',
      text: 'Organizaciones de la sociedad civil.',
    },
    {
      img:'../../../../assets/img/associations.png',
      text: 'Asociaciones internacionales y organismos multilaterales.',
    },    
    
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
