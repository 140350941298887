import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public showLoad: boolean = false;

  title = 'FE-SIE-P1701-Sitio-Informativo-Cibaef';
}
